<template>
    <div class="global-view-card" v-loading="pageLoad">
        <div class="global-detail-head">
            {{ title }}
        </div>
        <div class="global-detail-content" style="padding: 0 10px;">
            <el-form
                    ref="detailForm"
                    :model="detailForm"
                    :rules="detailRules"
                    class="global-detail-content"
                    label-width="auto"
                    label-suffix=":"
            >
                <el-form-item label="服务商名称" prop="thirdPartyName">
                    <el-input
                            v-model="detailForm.thirdPartyName"
                            placeholder="请输入服务商名称"
                    />
                </el-form-item>
                <el-form-item label="服务商CODE" prop="thirdPartyCode">
                    <el-input
                            v-model="detailForm.thirdPartyCode"
                            placeholder="请输入服务商CODE"
                    />
                </el-form-item>
                <el-form-item label="描述" prop="des">
                    <el-input
                            v-model="detailForm.des"
                            placeholder="请输入描述"
                    />
                </el-form-item>
                <el-form-item label="环境" prop="systemEnvironment">
                    <el-radio-group v-model="detailForm.systemEnvironment">
                        <el-radio label="dev">开发</el-radio>
                        <el-radio label="test">测试</el-radio>
                        <el-radio label="prod">生产</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="调用方式" prop="type">
                    <el-select v-model="detailForm.type">
                        <el-option value="ASYNC" label="异步"></el-option>
                        <el-option value="SYNC" label="同步"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="域名" prop="apiHost">
                    <el-input
                            v-model="detailForm.apiHost"
                            placeholder="请输入域名"
                    />
                </el-form-item>
                <el-form-item label="访问密钥" prop="accessKey" required>
                    <el-input
                            type="textarea"
                            :rows="6"
                            size="mini"
                            style="width: 100%;"
                            v-model="detailForm.accessKey"
                            placeholder="访问密钥"
                    ></el-input>
                </el-form-item>
                <el-form-item label="secret" prop="accessSecret" required>
                    <el-input
                            type="textarea"
                            :rows="6"
                            size="mini"
                            style="width: 100%;"
                            v-model="detailForm.accessSecret"
                            placeholder="secret"
                    ></el-input>
                </el-form-item>
                <el-form-item label="具体数据配置" prop="json" required>
                        <el-input
                                type="textarea"
                                :autosize="{minRows:6}"
                                size="mini"
                                style="width: 80%;"
                                v-model="detailForm.json"
                                placeholder="具体数据配置"
                        ></el-input>
                        <!--<el-button type="primary" size="mini" style="margin-left: 10px;" @click="editTaskDict('json')">格式化</el-button>-->
                </el-form-item>
                <el-form-item label="json key说明" prop="json" required>
                    <el-input
                            type="textarea"
                            :autosize="{minRows:6}"
                            size="mini"
                            style="width: 80%;"
                            v-model="detailForm.jsonKeyDes"
                            placeholder="json key说明"
                    ></el-input>
                    <!--<el-button type="primary" size="mini" style="margin-left: 10px;" @click="editTaskDict('jsonKeyDes')">格式化</el-button>-->
                </el-form-item>
                <el-form-item class="global-detail-form-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" :loading="saveLoad" @click="save">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {
        getThirdConfigDetail,addThirdConfig,editThirdConfig,
    } from "@/api/business";


    export default {
        data() {
            // var validateJSON = (rule, value, callback) => {
            //     if (value) {
            //         try {
            //             JSON.parse(value)
            //         } catch (err) {
            //             callback(new Error('具体数据配置JSON格式有误'))
            //         }
            //         callback()
            //     }else {
            //         callback(new Error('请完具体数据配置'))
            //     }
            // }
            // var validateJsonDes = (rule, value, callback) => {
            //     if (value) {
            //         try {
            //             JSON.parse(value)
            //         } catch (err) {
            //             callback(new Error('json key说明格式有误'))
            //         }
            //         callback()
            //     }else {
            //         callback(new Error('请json key说明'))
            //     }
            // }
            return {
                pageLoad: false,
                title: "新增三方资源配置",
                id: null,
                detailForm: {
                    id: this.$route.query.id || null,
                    thirdPartyName: "",
                    thirdPartyCode: "",
                    des: "",
                    systemEnvironment: "",
                    type: null,
                    apiHost: '',
                    accessKey: "",
                    accessSecret: '',
                    json: null,
                    jsonKeyDes: null
                },
                notLimited: false,
                limitDisable: false,
                detailRules: {
                    thirdPartyName: [{required: true, message: "请输入服务商名称"}],
                    thirdPartyCode: [{required: true, message: "请输入服务商CODE"}],
                    des: [{required: true, message: "请输入描述"}],
                    systemEnvironment: [{required: true, message: "请选择环境",trigger:'change'}],
                    type: [{required: true, message: "请选择调用方式"}],
                    apiHost: [{required: true, message: "请输入域名"}],
                    accessKey:  [{required: true, message: "请输入访问密钥"}],
                    accessSecret:  [{required: true, message: "请输入secret"}],
                    json:  [{required: true, message: "请输入具体数据配置"}],
                    jsonKeyDes: [{required: true, message: "请输入json key说明\""}]
                },
                saveLoad: false,
            };
        },
        created() {
            if (this.$route.query.id) {
                this.title = '编辑三方资源配置';
                this.getThirdConfigDetail();
            }
        },
        methods: {
            editTaskDict(name) {
                if (this.detailForm[name]) {
                    let taskParm = JSON.parse(this.detailForm[name]);
                    try {
                        this.detailForm[name] = JSON.stringify(taskParm, null, 4)
                    } catch (err) {
                        console.log(err)
                    }
                }
            },
            setLimited(val) {
                this.notLimited = val;
                if (val) {
                    this.detailForm.limitTime = 0;
                    this.limitDisable = true
                } else {
                    this.detailForm.limitTime = null;
                    this.limitDisable = false
                }
            },

            limitThree(name) {//正整数且只能输入3位
                this.detailForm[name] = this.detailForm[name].replace(/^(0+)|[^\d]+/g, '')
            },

            //新增三方资源配置
            addThirdConfig(form) {
                this.saveLoad = true;
                addThirdConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('添加成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            //编辑三方资源配置
            editThirdConfig(form) {
                this.saveLoad = true;
                editThirdConfig(
                    form
                ).then(() => {
                    this.saveLoad = false;
                    this.cancel()
                    this.$message.success('更新成功')
                }).catch(() => {
                    this.saveLoad = false;
                })
            },

            getThirdConfigDetail() {
                this.pageLoad = true;
                getThirdConfigDetail({
                    id: this.detailForm.id
                }).then((res) => {
                    Object.assign(this.detailForm, res.data);
                    this.detailForm.json = JSON.stringify(JSON.parse(this.detailForm.json),null,4);
                    this.detailForm.jsonKeyDes = JSON.stringify(JSON.parse(this.detailForm.jsonKeyDes),null,4);
                    this.pageLoad = false;
                }).catch(() => {
                    this.pageLoad = false;
                });
            },
            save() {
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        if (this.detailForm.id) {
                            this.editThirdConfig(this.detailForm)
                        } else {
                            this.addThirdConfig(this.detailForm)
                        }
                    }
                });
            },
            cancel() {
                this.$router.push("/thirdResource");
            }
        },
    };
</script>

<style lang="scss">
    .banner-detail-dialog {
        .el-dialog__body {
            padding: 16px;
            .el-pagination {
                text-align: right;
                margin-top: 16px;
            }
        }
    }
</style>
